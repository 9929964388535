import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import translationEN from "../locales/en/translation.json"
import translationES from "../locales/es/translation.json"

const options = {
  order: ["path"],
}

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources,
    fallbackLng: "en",
    supportedLngs: ["es", "en"],
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    returnObjects: true,
  })

i18next.languages = ["es", "en"]

export default i18next
