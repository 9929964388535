module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pumpal","short_name":"Pumpal","start_url":"/","icon":"static/favicon/favicon-32x32.png","icons":[{"src":"static/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"static/favicon/maskable_icon.png","sizes":"196x196","type":"image/png","purpose":"any maskable"}],"background_color":"#5E0EFF","theme_color":"#5E0EFF","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1a6e6ea2cf544a979588f54874423457"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[""]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
